
.page-main-menu {
	.main-menu-block {
		.main-menu {
			list-style: none;
			
			a {
				text-decoration: none;
				color: inherit;
				
				h3 {
					margin-bottom: 0;
				}
				p {
					margin-top: 0;
				}

			}
		}
	}
	
}

.page-wrapper {
	header {
		position: relative;
	}
}



